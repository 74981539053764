import { Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { NetworkSelectionWizardComponent } from '@components/network-selection-wizard/network-selection-wizard.component';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from '@services/settings.service';
import { StorageUtilities } from '@utilities/storage.utilities';
import { AuthStatus } from '@interfaces/auth-status.model';
import { GatedEntryOverlayConfig } from '@interfaces/gated-entry-overlay-config.model';
import { NetworkSelectionWizardConfig } from '@interfaces/network-selection-wizard-config.model';
import { combineLatest, of, first, Observable, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NetworkSelectionWizardService {
  public wizardEnabled = this.getWizardSetting();

  constructor(
    private storage: StorageUtilities,
    private bottomSheet: MatBottomSheet,
    private translateService: TranslateService,
    private settingsService: SettingsService,
  ) {}
  
  public getWizard(auth: AuthStatus): Observable<boolean> {
    return combineLatest([
      this.settingsService
        .getSetting('gated_entry', GatedEntryOverlayConfig)
        .pipe(first()),
    ]).pipe(
      switchMap(([gatedEntry]) => {
        const shouldOpenWizard = this.shouldOpenWizard(auth, gatedEntry.enabled);
        if (shouldOpenWizard) {
          this.openNetworkSelectWizard(auth.auth_status);
          return of(true);
        }
        return of(false);
      })
    );
  }

  private getWizardSetting(): Subscription {
    return this.settingsService
      .getSetting('network_selection_wizard', NetworkSelectionWizardConfig)
      .pipe(first())
      .subscribe((status) => this.wizardEnabled = status.enabled);
  }

  private openNetworkSelectWizard(loggedIn: boolean): void {
    const sheetRef =
      this.bottomSheet.open(NetworkSelectionWizardComponent, {
        panelClass: 'full-screen-sheet',
        disableClose: true,
        hasBackdrop: false,
        data: {
          loggedIn: loggedIn,
        },
        ariaModal: false,
        ariaLabel: this.translateService.instant('gated_entry_home_title')
      });
    sheetRef.afterDismissed().subscribe(() => this.endWizardSession());
  }
  
  private shouldOpenWizard(authStatus: AuthStatus, gatedEntryEnabled: boolean) {
    const isAuthenticated = authStatus.auth_status === true;
    const wizardIsOpen = this.storage.sessionStorageGet('wizardActive');
    const wizardIsComplete = this.storage.sessionStorageGet('wizardCompleted');
    
    switch (true) {
      case (gatedEntryEnabled):
        return false;
      case (isAuthenticated):
        return false;
      case (wizardIsOpen):
        return false;
      case (wizardIsComplete):
        return false;
      default:
        return true;
    }
  }

  private endWizardSession(): void {
    this.storage.sessionStorageRemove('wizardActive');
  }
}
