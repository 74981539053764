import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmployerStateSelectionComponent } from './employer-state-selection.component';
import { DlsButtonModule } from "@zelis/dls/button";
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field";
import { MatLegacyOptionModule } from "@angular/material/legacy-core";
import { MatLegacySelectModule } from "@angular/material/legacy-select";
import { TranslateModule } from "@ngx-translate/core";
import { WizardLoginLinkModule } from "@components/network-selection-wizard/wizard-login-link/wizard-login-link.module";
import { ReactiveFormsModule } from "@angular/forms";

@NgModule({
  declarations: [EmployerStateSelectionComponent],
  imports: [
    CommonModule, 
    DlsButtonModule,
    MatLegacyFormFieldModule,
    MatLegacyOptionModule,
    MatLegacySelectModule,
    TranslateModule, 
    WizardLoginLinkModule, 
    ReactiveFormsModule],
  exports: [EmployerStateSelectionComponent],
})
export class EmployerStateSelectionModule {}
