import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  Inject,
  Input,
  OnChanges,
  Optional,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import {
  MatLegacyListOption as MatListOption,
  MatLegacyNavList as MatNavList,
  MatLegacySelectionList as MatSelectionList,
} from '@angular/material/legacy-list';
import { LegacyTooltipPosition as TooltipPosition } from '@angular/material/legacy-tooltip';
import { Router } from '@angular/router';
import type { IconName } from '@zelis/dls/icons';
import type { LozengeEmphasis } from '@zelis/dls/lozenge';
import { coerceBoolean } from 'coerce-property';

@Component({
  selector: 'zelis-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ListItemComponent<TId extends string | number = string>
  implements OnChanges
{
  @HostBinding() class = 'zelis-dls';
  @HostBinding('class.hidden') isClosed = false;
  @HostBinding('class.checkbox-before') get checkboxBeforeClass() {
    return this.checkboxPosition === 'before';
  }
  @HostBinding('class.checkbox-after') get checkboxAfterClass() {
    return this.checkboxPosition === 'after';
  }

  @ViewChild(MatListOption) listOption?: MatListOption;

  @Input() primaryText!: string;
  @Input() secondaryText?: string;
  @Input() leftIconName?: IconName;
  @Input() rightIconName?: IconName;
  @Input() avatarSrc?: string;
  @Input() avatarPlaceholderIcon!: IconName;
  @Input() isProviderAvatar?: boolean;
  @Input() metadata?: string;
  @Input() lozengeText?: string;
  @Input() lozengeType?: 'theme' | 'low-emphasis-gray' | 'high-emphasis-yellow';
  @Input() lozengeColor?: string;
  @Input() lozengeEmphasis?: LozengeEmphasis;
  @Input() checkboxPosition: 'before' | 'after' = 'after';
  @Input() color?: ThemePalette;
  @Input() @coerceBoolean selected?: boolean;
  @Input() highlightTerm = '';
  @Input() @coerceBoolean dismissible = false;
  @Input() href = '';
  @Input() forwardedRouterLink = '';
  @Input() fragment = '';
  @Input() target = '_self';
  @Input() id?: TId;
  @Input() rightIconTooltipText!: string;
  @Input() rightIconTooltipPosition!: TooltipPosition;
  @Input() rightIconClass?: string;
  @Input() linkClass?: string;
  @Input() ariaLabel? = 'Icon';
  @Input() lozengeClasses?: string;

  @Output() public closed: EventEmitter<void> = new EventEmitter();
  @Output() public clickFn: EventEmitter<Event> = new EventEmitter();

  get checked() {
    return this.listOption?.selectedChange;
  }

  constructor(
    @Optional()
    @Inject(forwardRef(() => MatSelectionList))
    public selectionList: MatSelectionList,
    @Optional()
    @Inject(forwardRef(() => MatNavList))
    public navigationList: MatNavList,
    private window: Window,
    private router: Router
  ) {}

  ngOnChanges() {
    this.validateInputs();
  }

  public onClose(e: Event): void {
    e.stopPropagation();
    this.isClosed = true;
    this.closed.emit();
  }

  public onNavListItemClick(event: Event): void {
    this.clickFn.emit(event);
    if (this.href) {
      this.window.open(this.href, this.target);
      return;
    }
    if (this.forwardedRouterLink) {
      let options: undefined | object = undefined;
      if (this.fragment !== '') {
        options = { queryParamsHandling: 'merge', fragment: this.fragment };
      }
      this.router.navigate([this.forwardedRouterLink], options);
      return;
    }
  }

  private validateInputs() {
    if (this.href && this.forwardedRouterLink) {
      throw new Error('Cannot have both href and routerLink');
    }
  }
}
