import { SearchView } from '@components/+search/classes/search-view.class';
import { WindowService } from '@services/window.service';
import {
  Component,
  OnInit,
  OnDestroy,
  Inject,
  ViewChild,
  ChangeDetectorRef,
  NgZone,
  ElementRef,
  Renderer2,
} from '@angular/core';
import {
  Router,
  ActivatedRoute,
  ActivationEnd,
  NavigationStart,
  NavigationEnd,
  Event,
} from '@angular/router';
import { Subscription, Observable, interval, combineLatest, of, forkJoin } from 'rxjs';
import { AppConfigService } from '@services/app.config.service';
import { AppParamsService } from '@services/app.params.service';
import { AuthService } from '@services/auth.service';
import { ConfigurationService } from '@services/configuration.service';
import { FooterService } from '@services/footer/footer.service';
import { ProceduresService } from '@services/procedures.service';
import { SettingsService } from '@services/settings.service';
import { PcpService } from '@components/pcp/pcp.service';
import { TranslationsSettingsService } from '@services/translations/translations.settings.service';
import { RouteUtilities } from '@utilities/route.utilities';
import { LnpService } from '@services/lnp/lnp.service';
import { CcssService } from '@services/ccss/ccss.service';
import { NotificationOverlayComponent } from '@components/notification-overlay/notification-overlay.component';
import { BrowserDetectService } from '@services/browser-detect/browser-detect.service';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import {
  SubscriptionManager,
  GlobalFooterDisclaimer,
  GlobalFooterLinks,
} from '@zelis/platform-ui-components';
import { Idle } from 'idlejs/dist';
import { GoogleTagManagerDataLayerService } from '@services/analytics/google-tag-manager-data-layer.service';
import { MembersService } from '@services/members.service';
import { Title } from '@angular/platform-browser';
import { DOCUMENT, Location } from '@angular/common';
import { AuthStatus } from '@interfaces/auth-status.model';
import { HeaderParams } from '@interfaces/header-params.model';
import {
  first,
  mergeMap,
  switchMap,
  filter,
  startWith,
  delay,
  debounceTime,
  distinctUntilChanged,
  tap,
  map,
  take,
} from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { MsaSettingsService } from '@components/+msa/msa.settings.service';
import { GuidedSearchService } from '@services/guided-search/guided-search.service';
import { ChatService } from '@services/pat-chat/chat.service';
import { StickyFabService } from '@services/sticky-fab.service';
import { LogOutService } from '@services/logout.service';
import { FeaturesService } from '@services/features/features.service';
import { VimAppointmentsService } from '@services/vim-appointments/vim-appointments.service';
import { TrackByUtilities } from '@utilities/trackByFn.utilities';
import { GatedEntryService } from '@services/gated-entry/gated-entry.service';
import { SearchFiltersSettings } from '@components/+search/classes/search-filters-settings.class';
import { MatIconRegistryService } from '@services/mat-icon-registry.service';
import { HttpSpecMockConfigService } from '@services/http-spec-mock-config/http-spec-mock-config.service';
import { HttpMockConfig } from '@interceptors/http.mock.config';
import { environment } from '../environments/environment';
import { SkipLinksService } from '@components/skip-link/skip-links.service';
import { ProductAnalyticsService } from '@services/product-analytics/product-analytics.service';
import { CriticalParamsService } from '@services/critical-params/critical-params.service';
import { StorageUtilities } from '@utilities/storage.utilities';
import { Msa } from '@interfaces/msa.model';
import { NotificationButton } from '@interfaces/notication-button.interface';
import { ThemingService } from '@services/theming/theming.service';
import { RecentAutosuggestService } from '@services/guided-search/recent-autosuggest.service';
import { ConfigSearchFiltersService } from '@services/config-search-filters/config-search-filters.service';
import { NetworkSelectionWizardService } from '@services/network-selection-wizard/network-selection-wizard.service';
import { AppConfig } from '@interfaces/app-config.model';
import { LocationAutosuggest } from '@classes/location-autosuggest.class';
import { CouponsService } from '@services/coupons.service';
import { isEqual } from 'lodash';
import { AppParams } from '@interfaces/app.interface.appParams';
import { GatedEntryOverlayConfig } from "@interfaces/gated-entry-overlay-config.model";
import { NetworkSelectionWizardConfig } from "@interfaces/network-selection-wizard-config.model";

const IDLE_LOGOUT_MINUTES = 30;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [],
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('appContainer') appContainer;

  public subscriptions: SubscriptionManager = new SubscriptionManager();
  public showProgressBar: boolean = true;
  public bvaEnabled: boolean = false;
  public metaInfoEnabled: boolean = false;
  public showExternalLinkIndicator: boolean = false;
  public persistChat: boolean = false;
  public fabDisabled: boolean = true;
  public chatDisabled: boolean = true;
  public features: any = {};
  public suppressLogoLink: boolean = false;
  public vimAppointmentsEnabled: boolean = false;
  public trackByUtilities: TrackByUtilities = new TrackByUtilities();
  public trackByText: any = TrackByUtilities.getTrackByFn('text');
  public trackById: any = TrackByUtilities.trackByFnItemId;
  public footerDisclaimers: Observable<GlobalFooterDisclaimer[]> =
    this.footerService.getDisclaimers();
  public footerLinks: Observable<GlobalFooterLinks> =
    this.footerService.getLinks();
  public updatedAt: Observable<string> = this.footerService.getUpdatedAt();
  public themingCcssResolved: Observable<boolean> =
    this.listenToCcssThemingResolution();
  public copyrightYear: number = new Date().getFullYear();

  private header = new HeaderParams({});
  private onLoadParams: any = null;
  private initialLoad: boolean;
  private browserBackNavClick: boolean;
  private sessionExpiredDialog: MatDialogRef<NotificationOverlayComponent>;
  private externalLinksObserver: MutationObserver = this.listenToNewExternalLinks();

  constructor(
    public appConfigService: AppConfigService,
    private httpSpecMockConfigService: HttpSpecMockConfigService,
    public appParamsService: AppParamsService,
    public router: Router,
    public ccss: CcssService,
    public searchView: SearchView,
    public routeUtilities: RouteUtilities,
    private configurationService: ConfigurationService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private proceduresService: ProceduresService,
    private footerService: FooterService,
    private settingsService: SettingsService,
    private translateService: TranslateService,
    private pcpService: PcpService,
    private lnpService: LnpService,
    private translationsSettingsService: TranslationsSettingsService,
    private dialog: MatDialog,
    @Inject(DOCUMENT) private document,
    private windowService: WindowService,
    private browserDetect: BrowserDetectService,
    googleTagManagerDataLayer: GoogleTagManagerDataLayerService,
    private membersService: MembersService,
    private title: Title,
    private msaSettingsService: MsaSettingsService,
    private guidedSearchService: GuidedSearchService,
    private chatService: ChatService,
    private stickyFabService: StickyFabService,
    private changeDetector: ChangeDetectorRef,
    private logoutService: LogOutService,
    private featuresService: FeaturesService,
    private vimAppointmentsService: VimAppointmentsService,
    private gatedEntryService: GatedEntryService,
    private networkSelectionService: NetworkSelectionWizardService,
    private searchFiltersSettings: SearchFiltersSettings,
    private matIconRegistryService: MatIconRegistryService,
    private ngZone: NgZone,
    private skipLinksService: SkipLinksService,
    private productAnalyticsService: ProductAnalyticsService,
    private criticalParamsService: CriticalParamsService,
    private location: Location,
    private storage: StorageUtilities,
    private themingService: ThemingService,
    private recentAutoSuggestService: RecentAutosuggestService,
    private configSearchFiltersService: ConfigSearchFiltersService,
    public locationAutosuggest: LocationAutosuggest,
    private couponsService: CouponsService,
    private el: ElementRef,
    private renderer: Renderer2
  ) {
    this.disableAnimations();
    this.subscribeToRouteStabilized();
    googleTagManagerDataLayer.initialize();
    this.setOnLoadParams();
    this.subscriptions.add(this.subscribeToAuthStatus());
    this.subscriptions.add(this.subscribeToSignatureChange());
    this.initGetSettings();
    this.subscriptions.add(this.setTitleOnRouteChange());
    this.subscriptions.add(this.subscribeToAppConfig());
    this.setSessionStartTime();
    this.subscriptions.add(this.subscribeToMembers());
    this.subscriptions.add(this.subscribeToGuidedSearch());
    this.subscriptions.add(this.subscribeToSkipLinks());
    this.matIconRegistryService.addSvgIcons();
    this.watchBrowserBackNav();

  }

  ngOnInit() {
    this.initialLoad = true;
    this.subscriptions.add(this.lnpService.init().subscribe());
    this.subscriptions.add(this.themingService.clientPalettes.subscribe());
    this.subscriptions.add(this.subscribeToFeatures());
    this.subscriptions.add(this.authService.initPollSession());
    this.initializeProductAnalytics();
    this.extendSessionDialogOpen();
    this.subscriptions.add(this.subscribeToDetectChanges());
    this.subscriptions.add(
      this.recentAutoSuggestService.initRecentSearches().subscribe()
    );
    this.initializeSearchFiltersSettings();
  }

  ngOnDestroy() {
    this.subscriptions.destroy();
    this.externalLinksObserver.disconnect();
  }

  public listenForIdle(): void {
    this.ngZone.runOutsideAngular(() =>
      new Idle()
        .whenNotInteractive()
        .within(IDLE_LOGOUT_MINUTES)
        .do(() =>
          this.ngZone.run(() => {
            this.authService.logout();
            this.storage.localStorageSet('idleLogout', true);
          })
        )
        .start()
    );
  }

  private initGetSettings(): void {
    this.subscriptions.add(
      this.configurationService.listenForResolvedSignature()
        .pipe(take(1))
        .subscribe(() => {
          this.initializeTranslations();
          this.subscriptions.add(this.subscribeToCcss());
          this.subscriptions.add(this.subscribeToMsaSso());
          this.subscriptions.add(this.subscribeToMetaInfoConfig());
          this.subscriptions.add(this.subscribeToExternalLinksConfig());
          this.subscriptions.add(this.subscribeToSuppressLogoLinkConfig());
          this.subscriptions.add(this.subscribeToVimAppointments());
          this.subscriptions.add(this.setFabDisabled());
          this.subscriptions.add(this.setChatDisabled());
          this.subscriptions.add(this.checkPersistChat());
          this.subscribeToProcedures();
          this.setKeepAliveUrl();
          this.subscribeToCouponsEligible();
        })
    )
  }

  private subscribeToCouponsEligible(): void {
    this.subscriptions.add(
      this.couponsService.setRxCouponsEligibleSetting().subscribe()
    );
  }

  private initializeTranslations(): void {
    this.translateService.setDefaultLang('en');
    this.document.documentElement.lang = 'en';
    this.subscriptions.add(this.subscribeToTranslationsUpdate());
  }

  private subscribeToMembers(): Subscription {
    return this.membersService.getMember().subscribe();
  }

  private onRouteStabilized(): Observable<Event> {
    return this.router.events.pipe(debounceTime(1000), delay(1000));
  }

  /**
   * Route Stabilized
   * Add methods here that need to run after all resolutions and URL updates.
   * This also forces E2E tests to wait for a stable URL before testing.
   */
  private subscribeToRouteStabilized(): void {
    this.subscriptions.add(
      this.onRouteStabilized().subscribe(() => {
        scrollTo(0, 0);
      })
    );
  }

  // TODO: Workaround for change detection sometimes getting stuck, requiring a click.
  // Remove once root cause is fixed.
  private subscribeToDetectChanges(): Subscription {
    return interval(500).subscribe(() => this.changeDetector.detectChanges());
  }

  private initializeSearchFiltersSettings(): void {
    this.subscriptions.add([
      this.configSearchFiltersService
        .requestSettingsOnResolvedNetwork()
        .subscribe(),
      this.searchFiltersSettings.resolved.subscribe(),
    ]);
  }

  private subscribeToTranslationsUpdate(): Subscription {
    return this.translationsSettingsService.updateTranslations().subscribe();
  }

  private subscribeToSkipLinks(): Subscription {
    return this.router.events
      .pipe(filter((e) => e instanceof NavigationStart))
      .subscribe(() => this.skipLinksService.reset());
  }

  private subscribeToAuthStatus(): Subscription {
    return this.authService.authStatus
      .pipe(
        filter((authStatus: AuthStatus) => authStatus.resolved),
        distinctUntilChanged((prev, curr) => prev.url === curr.url),
        switchMap((authStatus: AuthStatus) => {
          if (authStatus.auth_status) {
            this.listenForIdle();
          }
          return combineLatest([
            this.listenToParams(),
            this.callEntryExperience(authStatus),
            this.checkSessionTimeout(authStatus)
          ])
        })
      )
      .subscribe();
  }

  private callEntryExperience(authStatus: AuthStatus): Observable<boolean> {
    return this.listenToCcssThemingResolution().pipe(
      filter((resolved) => resolved),
      switchMap(() =>
        forkJoin([
          this.settingsService.getSetting('gated_entry', GatedEntryOverlayConfig).pipe(first()),
          this.settingsService.getSetting('network_selection_wizard', NetworkSelectionWizardConfig).pipe(first()),
        ]).pipe(
          switchMap(([gatedEntry, networkSelectionWizard]) => {
            if (gatedEntry.enabled) {
              return this.gatedEntryService.getGatedEntry(authStatus, this.onLoadParams);
            }
            if (networkSelectionWizard.enabled) {
              return this.networkSelectionService.getWizard(authStatus);
            }
            return of(false);
          })
        )
      )
    );
  }

  private checkSessionTimeout(authStatus: AuthStatus): Observable<boolean> {
    if (this.storage.localStorageGet('idleLogout')) {
       return this.translationsSettingsService.translationsResolved
          .pipe(
            first((resolved) => !!resolved),
            tap(() => this.openLogoutDialog(authStatus))
          );
    }
    return of(false);
  }

  private openLogoutDialog(authStatus: AuthStatus): void {
    if (!this.sessionExpiredDialog) {
      this.sessionExpiredDialog = this.dialog.open(
        NotificationOverlayComponent,
        {
          width: '500px',
          data: {
            headline: 'app_logged_out_headline',
            body: 'app_logged_out_body',
            buttons: this.getButtons('after'),
            cancel: () => this.dialog.closeAll(),
            login: () => (window.location.href = authStatus.url),
          },
        }
      );
      this.sessionExpiredDialog.afterClosed().subscribe(() => {
        this.storage.localStorageRemove('idleLogout');
        this.sessionExpiredDialog = null;
      });
    }
  }

  private extendSessionDialog(): void {
    this.dialog.open(NotificationOverlayComponent, {
      width: '500px',
      data: {
        headline: 'app_before_session_expire_headline',
        body: 'app_before_session_expire_body',
        buttons: this.getButtons('before'),
        logout: () => this.authService.logout(),
        close: () => this.dialog.closeAll(),
      },
    });
  }

  private checkAuthStatus(): void {
    this.authService.authStatus.subscribe((auth: AuthStatus) => {
      auth.auth_status ? this.extendSessionDialog() : null;
    });
  }

  private extendSessionDialogOpen(): void {
    this.ngZone.runOutsideAngular(() =>
      new Idle()
        .whenNotInteractive()
        .within(IDLE_LOGOUT_MINUTES - 1)
        .do(() => this.checkAuthStatus())
        .start()
    );
  }

  private subscribeToSignatureChange(): Subscription {
    return this.authService.authStatus
      .pipe(
        filter((authStatus: AuthStatus) => authStatus.resolved),
        take(1),
        switchMap((authStatus) => this.watchForSignatureChange(authStatus.auth_status)),
      ).subscribe();
  }

  private watchForSignatureChange(authStatus: boolean): Observable<string> {
    let initialLoad = true;
    return this.criticalParamsService.criticalParamsSubject.pipe(
        distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
        filter((params: any) => {
          if (initialLoad) {
            initialLoad = false;
            return true;
          }
          return (
            params.ci && params.geo_location && params.locale && params.network_id
          );
        }),
        switchMap((params) => this.configurationService.getSignature(params, authStatus))
      )
  }

  private watchBrowserBackNav(): void {
    this.location.subscribe((nav) => {
      if (nav.pop && nav.type === 'popstate') {
        this.browserBackNavClick = true;
      }
    });
  }

  private isCriticalParamsComplete(): boolean {
    const { ci, geo_location, network_id } =
      this.criticalParamsService.criticalParams;
    return !(ci === '' || geo_location === '' || network_id === '');

  }

  private listenToParams(): Observable<AppParams> {
    return this.appParamsService
      .onRouteChange(this.route)
      .pipe(
        filter((params: AppParams) => !!params),
        tap((params: AppParams)=> {
          const criticalParamsComplete = this.isCriticalParamsComplete();
          if (
            this.initialLoad ||
            !criticalParamsComplete ||
            this.browserBackNavClick
          ) {
            const criticalParams = new HeaderParams(params);
            this.criticalParamsService.setCriticalParams(criticalParams);
            this.initialLoad = false;
            this.browserBackNavClick = false;
          }
        })
      );
  }

  private setLanguageAndLocale(): void {
    if (this.header.locale === 'null') {
      this.header.locale = 'en';
    }
    const lang = (this.header.locale || 'en');

    this.document.documentElement.lang = lang;

    if (this.translateService.currentLang !== lang) {
      this.translateService.use(lang);
    }

    this.browserDetect.checkBrowser();
  }

  private updateParams(appConfig: AppConfig): Promise<any> {
    const fragment = this.routeUtilities.getFragmentFromUrl();
    const params = this.routeUtilities.getParamsFromUrl();
    this.cleanUrlParams(params, appConfig);
    const navParams = {
      queryParams: { ...params, ...this.header },
      replaceUrl: true,
    };
    delete navParams.queryParams.identifier;
    if (fragment !== '') {
      navParams['fragment'] = fragment;
    }
    return this.ngZone.run(() => this.router.navigate([], navParams));
  }

  private cleanUrlParams(params: any, appConfig: AppConfig): void {
    this.routeUtilities.cleanUrlParam(
      params,
      this.router.url,
      ['carepath', 'profile', 'search'],
      'carepath_change'
    );
    this.routeUtilities.cleanUrlParam(
      params,
      this.router.url,
      ['search'],
      'search_view'
    );
    this.routeUtilities.cleanUrlParam(
      params,
      this.router.url,
      ['surgery-support', 'expert-medical'],
      'search_ref'
    );
    this.routeUtilities.cleanUrlParam(
      params,
      this.router.url,
      ['profile'],
      'out_of_network'
    );
    if (appConfig.e2e) {
      const httpMockConfig = new HttpMockConfig();
      this.routeUtilities.cleanUrlParam(
        params,
        this.router.url,
        [],
        httpMockConfig.keyName
      );
    }
  }

  private waitOnNavEndToUpdateParams(appConfig: AppConfig): Observable<any> {
    return this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      switchMap(() => of(this.updateParams(appConfig)))
    );
  }

  private mergeHeaderKeys(header): void {
    Object.keys(header).forEach((key) => {
      if (header[key]) {
        this.header[key] = header[key];
      }
    });
  }

  private subscribeToAppConfig(): Subscription {
    return this.appConfigService.config
      .pipe(
        switchMap((appConfig: AppConfig) =>
          combineLatest([
            this.setCriticalParams(appConfig),
            this.authService.subscribeToLoginLinksFromSetting(appConfig),
          ])
        )
      )
      .subscribe();
  }

  private setCriticalParams(appConfig: AppConfig): Observable<any> {
    return this.criticalParamsService.criticalParamsSubject.pipe(
      distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
      tap((criticalParams) => {
        this.mergeHeaderKeys(criticalParams);
        if (this.header.network_id) {
          this.setLanguageAndLocale();
        }
      }),
      switchMap(() =>
        this.router.navigated
          ? of(this.updateParams(appConfig))
          : this.waitOnNavEndToUpdateParams(appConfig)
      )
    );
  }

  private setSessionStartTime(): void {
    if (!this.storage.sessionStorageGet('sessionStartTime')) {
      this.storage.sessionStorageSet('sessionStartTime', new Date());
    }
  }

  private setTitleOnRouteChange(): Subscription {
    return this.router.events
      .pipe(
        filter(
          (event) =>
            event instanceof ActivationEnd &&
            event['snapshot'].data &&
            event['snapshot'].data.title
        ),
        mergeMap((event) =>
          this.translateService.get(event['snapshot'].data.title)
        )
      )
      .subscribe((title) => this.title.setTitle(title));
  }

  private subscribeToMsaSso(): Subscription {
    return this.msaSettingsService
      .getMsaSso()
      .pipe(filter((msa: Msa) => !!(msa.sso && msa.msaId && msa.bvaEndpoint)))
      .subscribe(() => (this.bvaEnabled = true));
  }

  private subscribeToMetaInfoConfig(): Subscription {
    return this.settingsService
      .getSetting('meta_info')
      .subscribe((metaInfo) => {
        this.metaInfoEnabled = metaInfo && metaInfo.enabled;
      });
  }

  private subscribeToExternalLinksConfig(): Subscription {
    return this.settingsService
      .getSetting('external_link_indicator')
      .subscribe((indicator) => {
        this.showExternalLinkIndicator = indicator;
        if (indicator) {
          this.externalLinksObserver.observe(this.el.nativeElement, { childList: true, subtree: true});
        }
      });
  }

  private subscribeToSuppressLogoLinkConfig(): Subscription {
    return this.settingsService
      .getSetting('suppress_logo_link')
      .subscribe((suppressLogoLink) => {
        this.suppressLogoLink = suppressLogoLink;
      });
  }

  private setKeepAliveUrl(): void {
    this.settingsService
      .getSetting('keep_alive_url')
      .pipe(first())
      .subscribe((keepAliveUrl: string) => {
        this.authService.setKeepAliveUrl(keepAliveUrl);
      });
  }
  
  private subscribeToGuidedSearch(): Subscription {
    return this.guidedSearchService.isReady.subscribe(() => {
      this.showProgressBar = false;
      this.changeDetector.detectChanges();
    });
  }

  private checkPersistChat(): Subscription {
    return this.chatService.persistChat
      .pipe(startWith(null), delay(0))
      .subscribe((persist: boolean) => (this.persistChat = persist));
  }

  private setChatDisabled(): Subscription {
    return this.chatService
      .chatDisabled()
      .pipe(startWith(null), delay(0))
      .subscribe((disabled: boolean) => (this.chatDisabled = disabled));
  }

  private setFabDisabled(): Subscription {
    return this.stickyFabService
      .fabDisabled()
      .pipe(startWith(null), delay(0))
      .subscribe((disabled: boolean) => (this.fabDisabled = disabled));
  }

  private subscribeToFeatures(): Subscription {
    return this.featuresService.getFeatureFlags().subscribe((features) => {
      this.features = features;
      this.logoutService.setShouldResetAccountOnLogout(
        features && features.reset_account_on_logout
      );
    });
  }

  private subscribeToVimAppointments(): Subscription {
    return this.vimAppointmentsService.getVimConfig().subscribe((vimConfig) => {
      this.vimAppointmentsEnabled = vimConfig.enabled;
    });
  }

  private subscribeToCcss(): Subscription {
    return this.onRouteStabilized()
      .pipe(
        switchMap(() => this.configurationService.listenForResolvedSignature()),
        take(1),
        switchMap(() => this.ccss.getCcss),
      )
      .subscribe(() => {
        this.changeDetector.detectChanges();

      });
  }

  private setOnLoadParams(): void {
    this.onLoadParams = this.routeUtilities.getParamsFromUrl();
  }

  private subscribeToProcedures(): void {
    this.subscriptions.add(
      this.proceduresService.getProcedures().subscribe(() => {
        this.changeDetector.detectChanges();
      })
    );
  }

  private disableAnimations(): void {
    if (environment.e2e) {
      document.body.classList.add('animate-disabled');
    }
  }

  private initializeProductAnalytics(): void {
    this.subscriptions.add(
      this.productAnalyticsService.initialize().subscribe()
    );
  }

  private getButtons(state: string): NotificationButton[] {
    const buttons = {
      before: [
        {
          translation: 'app_before_session_expire_logout',
          method: 'logout',
        },
        {
          translation: 'app_before_session_expire_stay_logged_in',
          method: 'close',
        },
      ],
      after: [
        {
          translation: 'app_after_session_expire_cancel',
          method: 'cancel',
        },
        {
          translation: 'app_after_session_expire_login',
          method: 'login',
        },
      ],
    };
    return buttons[state];
  }

  private listenToCcssThemingResolution(): Observable<boolean> {
    return combineLatest([
      this.ccss.resolved,
      this.themingService.resolved,
    ]).pipe(map(([ccss, theme]) => ccss && theme));
  }

  private listenToNewExternalLinks() {
   return new MutationObserver(() => {
     const treeWalker = this.document.createTreeWalker(this.el.nativeElement,
       NodeFilter.SHOW_ELEMENT,
       node => {
         if (node.getAttribute('href')?.startsWith('http') &&
           !node.classList.contains('no-external-link-indicator') &&
           !node.classList.contains('attribution') &&
           !node.classList.contains('login') &&
           !node.classList.contains('header-login-button') &&
           !node.classList.contains('award-container') &&
           !node.classList.contains('certified-link') &&
           !node.classList.contains('award-icon')) {
           return NodeFilter.FILTER_ACCEPT
         } else {
           return NodeFilter.FILTER_SKIP
         }
       });
     let currentNode = treeWalker.currentNode;
     while (currentNode) {
       if (currentNode.nodeName === 'A') {
         const anchor = currentNode as HTMLAnchorElement;
         this.renderer.setAttribute(anchor, 'aria-label', this.translateService.instant('app_global_opens_in_new_tab'));
         this.renderer.setAttribute(anchor, 'rel', 'noopener noreferrer');
       }
       currentNode = treeWalker.nextNode();
     }
    });
  }

}
