<div class="text-center text-palette-m3-system-gray-10 flex flex-col items-center p-4 md:p-0">

  <h3 class="heading-3 font-bold mb-10">
    {{ 'network_selection_wizard_employer_header' | translate }}
  </h3>

  <div class="form-field-container employer-state-selection w-full md:w-[584px]" [class.w-full]="breakpoints.isMobile">
    <mat-form-field 
      appearance="outline" 
      [hideRequiredMarker]="true" 
      class="w-full"
      [attr.aria-label]="'network_selection_wizard_employer_field_label' | translate"
    >
      <mat-select 
        class="mb-1"
        [formControl]="planState" 
        [compareWith]="selectCompare"
        [placeholder]="'network_selection_wizard_employer_field_label' | translate"
      >
        <mat-option *ngFor="let state of planStates, let i = index; trackBy: trackByStateCodeFn" [value]="state"
                    [attr.data-cy]="'mat-option-' + i">
          {{ state.state_name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="mb-10 sm:w-[200px]">
    <zelis-button
      size="medium"
      color="system-blue"
      variant="flat"
      fullWidth="true"
      truncate="false"
      (click)="onContinueClick()"
      (keydown.enter)="onContinueEnterPress()"
      [disabled]="planState.invalid"
      data-cy="network-selection-wizard-employer-select-state-button"
    >{{ 'network_selection_wizard_employer_action_button' | translate }}</zelis-button>
  </div>
  
  <app-wizard-login-link></app-wizard-login-link>
</div>
