import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from "@angular/core";
import { UuxNetwork } from "@interfaces/uux-network.model";
import { ProductType } from "@enums/uux-marketing-plans.enum";
import { StorageUtilities } from "@utilities/storage.utilities";
import { Breakpoints } from "@classes/breakpoints.class";
import { CriticalParamsService } from "@services/critical-params/critical-params.service";

@Component({
  selector: 'app-plan-list-selection',
  templateUrl: './plan-list-selection.component.html',
})
export class PlanListSelectionComponent implements OnInit, OnDestroy, OnChanges {
  @HostBinding() class = 'zelis-dls';

  @Input() public pageHeading: string;
  @Input() public description: string;
  @Input() public showAllPlans: boolean;
  @Input() plans: UuxNetwork[];
  @Output() public closeOverlay: EventEmitter<void> = new EventEmitter();
  @Output() public refreshPlans: EventEmitter<ProductType> = new EventEmitter();
  public selectedPlan: UuxNetwork;
  public storage: StorageUtilities = new StorageUtilities();

  constructor(
    public breakpoints: Breakpoints,
    private criticalParamsService: CriticalParamsService,
  ) {}

  ngOnInit(): void {
    this.storage.sessionStorageSet('wizardPlanListPage', true);
    if (this.showAllPlans) {
      this.refreshPlans.next(ProductType.all);
    } else {
      this.refreshPlans.next(this.plans[0].product_type as ProductType);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.plans && this.plans?.length === 1) {
      this.selectedPlan = this.plans[0];
    }
  }

  public handleSelect(plan: UuxNetwork): void {
    this.selectedPlan = plan;
  }

  public handleKeyDownSelect(event: KeyboardEvent, plan: UuxNetwork): void {
    if (event.key === 'Enter' || event.key === ' ') {
      this.selectedPlan = plan;
    }
  }
  public handleSubmit(): void {
    this.criticalParamsService.setCriticalParams({
      ci: this.selectedPlan.ci,
    });
    this.storage.sessionStorageSet('uuxSelectedNetwork', this.selectedPlan);
    this.storage.sessionStorageSet('wizardCompleted', true);
    this.closeOverlay.emit();
  }

  ngOnDestroy(): void {
    this.storage.sessionStorageRemove('wizardPlanListPage');
  }
}
