import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { NotificationOverlayComponent } from '../../notification-overlay';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { AccountsService } from '../../../services/accounts.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { SubscriptionManager } from '@zelis/platform-ui-components';
import { FeaturesService } from '../../../services/features/features.service';
import { RouteUtilities } from '../../../utilities/route.utilities';
import { CriticalParams } from '@interfaces/critical-params.interface';
import { CriticalParamsService } from '@services/critical-params/critical-params.service';
import { StorageUtilities } from '@utilities/storage.utilities';
import { SettingsService } from '@services/settings.service';
import { filter } from 'rxjs/operators';
import { TrackByUtilities } from '@utilities/trackByFn.utilities';
import {
  LangDropdown,
  LangDropdownItem,
} from '@interfaces/language-dropdown.model';
import { NetworkToutService } from '@services/network-tout/network-tout.service';

@Component({
  selector: 'app-global-language-select',
  templateUrl: './global-language-select.component.html',
  styleUrls: ['./global-language-select.component.scss'],
})
export class GlobalLanguageSelectComponent implements OnInit {
  @Input() isMobile: boolean;

  static readonly DEFAULT_LANGUAGE = 'en';
  static readonly MORE_LANGUAGES = 'more';

  public language: string;
  public dialogRef: MatDialogRef<any>;
  public subscriptions = new SubscriptionManager();
  public meaningfulAccessLanguage: boolean = false;
  public displayMultipleLang: boolean;
  public multipleLangArray: LangDropdownItem[];
  public trackByLanguage: any = TrackByUtilities.getTrackByFn('language');
  private storage = new StorageUtilities();

  constructor(
    public dialog: MatDialog,
    public accountsService: AccountsService,
    private translateService: TranslateService,
    private featuresService: FeaturesService,
    private routeUtilities: RouteUtilities,
    private criticalParamsService: CriticalParamsService,
    private settingsService: SettingsService,
    private networkToutService: NetworkToutService
  ) {}

  ngOnInit(): void {
    this.getHeaderLanguage();
    this.setMeaningfulAccessLanguage();
    this.subscriptions.add(this.subscribeToMultipleLang());
  }

  public setLanguage(lang: string): void {
    this.storage.sessionStorageSet('locale', lang);
    this.reloadUsingNewLanguage(lang);
  }


  public openLanguagesOverlay(): void {
    this.subscriptions.add(
      this.accountsService.getPages().subscribe((pages) => {
        if (this.dialogRef || !pages.meaningfulaccesslang) {
          return;
        }
        if (this.isMobile) {
          this.openMobile(pages);
        } else {
          this.openDesktop(pages);
        }
        this.listenForClose();
      })
    );
  }

  public listenForClose(): Subscription {
    return this.dialogRef
      .afterClosed()
      .subscribe(() => (this.dialogRef = null));
  }

  private subscribeToMultipleLang(): Subscription {
    return this.settingsService
      .getSetting('multiple_language')
      .pipe(filter((data: LangDropdown) => !!data))
      .subscribe((data) => {
        this.displayMultipleLang = data.display_dropdown_select;
        this.multipleLangArray = data.languages;
      });
  }

  private openMobile(pages): void {
    this.dialogRef = this.dialog.open(NotificationOverlayComponent, {
      panelClass: 'fullscreen-overlay',
      data: {
        body: pages.meaningfulaccesslang[this.translateService.currentLang],
      },
    });
  }

  private openDesktop(pages): void {
    this.dialogRef = this.dialog.open(NotificationOverlayComponent, {
      width: '1000px',
      data: {
        body: pages.meaningfulaccesslang[this.translateService.currentLang],
      },
    });
  }

  private setMeaningfulAccessLanguage(): void {
    this.subscriptions.add(
      this.featuresService.getFeatureFlags().subscribe((features) => {
        this.meaningfulAccessLanguage =
          features && features.meaningful_access_language;
      })
    );
  }

  private reloadUsingNewLanguage(lang: string): void {
    if (lang === this.language) {
      return;
    }
    this.networkToutService.removeSessionToutNetworkSelection();
    const parsedUrl = this.routeUtilities.parseUrl();
    parsedUrl.searchObject.locale = lang;
    const newUrl = this.routeUtilities.stringifyParsedUrl(parsedUrl, {
      fullyQualified: true,
      includeSearch: true,
    });
    this.routeUtilities.goToUrl(newUrl);
  }

  private getHeaderLanguage(): void {
    this.subscriptions.add(
      this.criticalParamsService.criticalParamsSubject.subscribe(
        (header: CriticalParams) => {
          if (header.locale === 'null') {
            header.locale = 'en';
          }
          this.language = (header.locale || 'en');
        }
      )
    );
  }
}
